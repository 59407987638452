<template>
  <div class="layout">
      <div class="box">
        <h1 class="title">智能眼镜远程协作登录</h1>
        <!--s: account-->
        <div class="account-box">
          <div class="layout-login-username">
            <div class="layout-login-item">
              <Input prefix="ios-contact-outline" v-model="userInfo.userName" size="large" placeholder="请输入您的手机号"/>
            </div>
          </div>
          <div class="layout-login-password" >
            <div class="layout-login-item">
              <Input prefix="ios-lock-outline" type="password" password v-model="userInfo.password" size="large" placeholder="请输入您的密码"/>
            </div>
          </div>
        </div>
        <!--e: account-->
        <!--s: submit-->
        <div class="layout-login-submit">
          <Button type="primary" size="large" long :loading="loadingStatus" @click="onSignInCheck">
            <span v-if="!loadingStatus">生成二维码</span>
            <span v-else>生成二维码</span>
          </Button>
        </div>
        <!--e: submit-->
      </div>
      <Modal
        v-model="codeVisible"
        footer-hide
        :closable="false"
      >
        <h1 class="title1">灵云 登陆二维码</h1>
        <h2 class="title2">使用眼镜【灵云】软件扫描登陆</h2>
        <vue-qr :logoSrc="logoSrc" :text="QRCode" size="500" class="layout-code"></vue-qr>
      </Modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { userSignInService,getQRCodeService } from '@/service/user-service';
import { onDebounce, validatePhone,isMobile } from '@/utils/tools'
// import Cookies from 'js-cookie';
// import { mapMutations } from 'vuex'
import { BUSINESS_CODE_MODE } from '@/service/business-enum';
import { LOGIN_CHECK_MESSAGE } from '@/service/user-service/user-enum';
import {Message,Button, Input, Icon,Modal } from 'iview';
Vue.component('Button', Button);
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Modal', Modal);
import vueQr from 'vue-qr'
import wx from 'weixin-js-sdk'
export default {
    name: "Index",
    components: {
      vueQr
    },
    data(){
        return{
            loadingStatus:false, //loading 当前状态 请求发起时设为true 结束设为false ps:等待效果防止用户点击过快
            QRCode:'',
            logoSrc:require("@/assets/logo-img.png"),
            codeVisible:false,
            userInfo:{
                userName:'',
                password:'',
            }
        }
    },
  mounted() {
    wx.config({
      debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: 'wxa6edc4fba46a6dc9', // 必填，公众号的唯一标识
      timestamp: '', // 必填，生成签名的时间戳
      nonceStr: '', // 必填，生成签名的随机串
      signature: 'WCRxlGBvXQgvjEJ7uTZpz6gPPr30yePxEiLZdsDJj1G',// 必填，签名
      jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的JS接口列表
    });
    wx.updateAppMessageShareData({
      title: '1233333', // 分享标题
      desc: '', // 分享描述
      link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: '', // 分享图标
      success: function () {
        // 设置成功
      }
    })
  },
  methods:{
      /**
       *  校验方法 通过之后开启防抖函数 调用登录方法
       *  @param account && password
       */
      onSignInCheck() {
        if(!validatePhone(this.userInfo.userName) || this.userInfo.password === ''){
          Message.error(LOGIN_CHECK_MESSAGE.USERNAME_OR_PASSWORD_FREE_ERROR)
        }else{
          onDebounce(this.onSignIn(),1500)
        }
      },
      /**
       * 登录方法
       * @returns {Promise<void>}
       */
      async onSignIn() {
          try {
            this.loadingStatus = true
            let obj = {"loginAccount": this.userInfo.userName,"password":this.userInfo.password,"terminal":isMobile()}
            const userData = await userSignInService(obj)
            switch (userData.code){
              case BUSINESS_CODE_MODE.SUCCESS:  //业务成功
                // this.SET_USER_INFO(userData.data.token)
                sessionStorage.setItem("token", userData.data.token);
                this.onQRCode(userData).then()
                break;
              case BUSINESS_CODE_MODE.USERNAME_OR_PASSWORD_FREE_ERROR: //用户名或密码错误
                Message.error(LOGIN_CHECK_MESSAGE.USERNAME_OR_PASSWORD_FREE_ERROR);
                this.loadingStatus = false
                break;
              case BUSINESS_CODE_MODE.USER_NOT_AUTHORIZED_TO_LOGIN:
                Message.error('用户无权登录！');
                this.loadingStatus = false;
                break;
            }
          }catch (error){
            error.message?Message.error(error.message):console.log(error)
            this.loadingStatus = false
          }
        },
      /**
       * 获取二维码
       * @returns {Promise<void>}
       */
      async onQRCode(userData) {
        try {
          let params = {
            token : userData.data.token
          }
          const data  = await getQRCodeService(params);
          if(data.code === 'SUCCESS'){
            this.QRCode = data.data
            this.codeVisible = true
          }else{
            Message.error('获取失败！')
            this.codeVisible = false
          }
          this.loadingStatus = false
        }catch (error) {
          this.codeVisible = false
          this.loadingStatus = false
          console.log(error)
        }
      }
      },
};
</script>
<style lang="less">
.layout{
    width:7.5rem;
    margin: 0 auto;
    height:100%;

    .title{
        width:100%;
        text-align: center;
        font-size: .3rem;
        margin-bottom:.5rem;
    }
    .box{
      width:6.9rem;
      position: relative;
      top:30%;
      left:50%;
      transform: translateX(-50%);
    }
}
.layout-code{
  width:100%;
  border-radius: 6px;
}
.title1{
  float:left;
  width:100%;
  font-size: .4rem;
  text-align: center;
  color:#000;
  margin-top: .1rem;
}
.title2{
  float:left;
  width:100%;
  padding:0 .4rem;
  font-size: .26rem;
  text-align: center;
  color:#000;
  margin-top: .2rem;
}
.layout-login-item{margin-bottom:.3rem;}
.layout-login-submit{margin-bottom:.3rem;}
</style>
